import React from 'react'
import { media, Capital, H1, Transition } from '@thesisedu/web/dist'
import { graphql, useStaticQuery } from 'gatsby'
import {
  RocketOutlined,
  UnorderedListOutlined,
  IssuesCloseOutlined,
  AppstoreOutlined,
  PlayCircleOutlined
} from '@ant-design/icons'
import styled from 'styled-components'
import { BrochureCardSmall } from '../styled'
import { VideoSeriesQuery } from '../../graphqlTypes'
import { VideoSeriesCard } from './VideoSeriesCard'

interface CategoryIconMap {
  [categoryName: string]: React.ReactElement
}
const ICONS: CategoryIconMap = {
  'Getting Started': <RocketOutlined />,
  Features: <UnorderedListOutlined />,
  Grading: <IssuesCloseOutlined />,
  Integrations: <AppstoreOutlined />,
  Demo: <PlayCircleOutlined />
}

const videoSeriesQuery = graphql`
  query videoSeries {
    content: allFile(
      sort: { order: [ASC], fields: [name] }
      filter: { sourceInstanceName: { eq: "video-series" } }
    ) {
      edges {
        node {
          ...VideoSeriesPiece
        }
      }
    }
  }
`

export function useVideoSeriesQuery() {
  const result = useStaticQuery<VideoSeriesQuery>(videoSeriesQuery)
  return result.content.edges.map((edge) => edge.node)
}

export function VideoSeriesWidget() {
  const videos = useVideoSeriesQuery()
  const categories = Array.from(
    videos.reduce<Set<string>>(
      (acc, video) => acc.add(video.childMarkdownRemark.frontmatter.category),
      new Set<string>()
    )
  )
  const [selectedTab, setSelectedTab] = React.useState<string | undefined>(categories[0])
  const categoryVideos = videos.filter(
    (v) => v.childMarkdownRemark.frontmatter.category === selectedTab
  )
  return (
    <>
      <Capital isBlock color={'@primary-color'}>
        Learn
      </Capital>
      <H1 isBlock>Let's break it down.</H1>
      <TabContainer>
        <div>
          {categories.map((category) => (
            <Tab
              key={category}
              className={selectedTab === category ? 'selected' : ''}
              onClick={() => {
                setSelectedTab(category)
              }}
            >
              {ICONS[category]}
              <Capital>{category}</Capital>
            </Tab>
          ))}
        </div>
      </TabContainer>
      <Transition state={selectedTab || 'none-selected'} type={'fade-scale'}>
        <ContentContainer>
          {categoryVideos.map((v) => (
            <div>
              <VideoSeriesCard video={v} key={v.id} />
            </div>
          ))}
        </ContentContainer>
      </Transition>
    </>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${(props) => props.theme['@size-s']};
  align-items: stretch;
  justify-content: flex-start;
  > div {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding: 0 ${(props) => props.theme['@size-s']} ${(props) => props.theme['@size-l']}
      ${(props) => props.theme['@size-s']};
    width: 100%;
    ${media.md} {
      width: 50%;
    }
    ${media.lg} {
      width: calc(33% + ${(props) => props.theme['@size-s']} * (1 / 5));
    }
  }
`
const TabContainer = styled(BrochureCardSmall)`
  overflow-x: auto;
  width: 100%;
  margin-bottom: ${(props) => props.theme['@size-xl']};
  padding: 0 ${(props) => props.theme['@size-s']};
  > div {
    display: inline-flex;
    align-items: flex-end;
    justify-content: space-around;
    padding: 0 ${(props) => props.theme['@size-l']};
    text-align: center;
    min-width: 100%;
  }
`
const Tab = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: ${(props) => props.theme['@size-m']} ${(props) => props.theme['@size-s']}
    ${(props) => props.theme['@size-s']} ${(props) => props.theme['@size-s']};
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  transition: background 0.25s linear;
  background: transparent;
  * {
    transition: color 0.25s linear;
    color: ${(props) => props.theme['@gray-5']} !important;
  }
  > :first-child {
    font-size: ${(props) => props.theme['@size-m']};
    margin-bottom: ${(props) => props.theme['@size-s']};
  }
  &:not(:last-child) {
    margin-right: ${(props) => props.theme['@size-s']};
  }
  &::after {
    display: block;
    position: absolute;
    content: ' ';
    right: 0;
    bottom: -4px;
    left: 0;
    height: 4px;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    transition: bottom 0.25s ease-in-out;
    background: ${(props) => props.theme['@primary-color']};
  }
  &:hover *,
  &.selected * {
    color: ${(props) => props.theme['@gray-7']} !important;
  }
  &:hover {
    background: ${(props) => props.theme['@background-color-base']};
  }
  &.selected::after {
    bottom: 0;
  }
`
