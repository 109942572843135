import * as React from 'react'
import { navigate } from 'gatsby'
import { useVideoSeriesQuery } from '../../components/video-series/VideoSeriesWidget'

const TutorialSeriesContent: React.FC = () => {
  const tutorials = useVideoSeriesQuery()
  const firstTutorial = tutorials[0]?.childMarkdownRemark.fields.slug
  React.useEffect(() => {
    if (firstTutorial) {
      navigate(`.${firstTutorial}`, { replace: true })
    }
  }, [firstTutorial])
  return <p>No tutorials yet!</p>
}

const TutorialSeriesPage: React.FC = () => <TutorialSeriesContent />

export default TutorialSeriesPage
