import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { H3Alternate, Body, Block } from '@thesisedu/web/dist'
import { HoverBrochureCardSmall } from '../styled'
import { VideoSeriesPieceFragment } from '../../graphqlTypes'

export interface VideoSeriesCardProps {
  video: VideoSeriesPieceFragment
}
export function VideoSeriesCard({ video }: VideoSeriesCardProps) {
  const { frontmatter } = video.childMarkdownRemark
  return (
    <Link to={`/learn/tutorial-series${video.childMarkdownRemark.fields.slug}`}>
      <Container>
        <Img
          fluid={frontmatter.thumb.childImageSharp.fluid}
          alt={frontmatter.title}
          style={{ width: '100%' }}
        />
        <Block marginTop={'@size-s'}>
          <H3Alternate isBlock>{frontmatter.title}</H3Alternate>
          <Body>{frontmatter.length}</Body>
          <Block marginTop={'@size-xs'}>
            <Body color={'@text-color-secondary'}>{frontmatter.summary}</Body>
          </Block>
        </Block>
      </Container>
    </Link>
  )
}

const Container = styled(HoverBrochureCardSmall)`
  padding: ${(props) => props.theme['@size-s']} ${(props) => props.theme['@size-s']}
    ${(props) => props.theme['@size-l']} ${(props) => props.theme['@size-s']};
  color: ${(props) => props.theme['@text-color']};
`

export const fragment = graphql`
  fragment VideoSeriesPiece on File {
    id
    childMarkdownRemark {
      html
      fields {
        slug
      }
      frontmatter {
        title
        thumb {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        videoId
        category
        length
        summary
      }
    }
  }
`
